import {
  Box,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { loadUserData } from "../../../actions/actions";
import { APP_CONFIG } from "../../../constants/config";
import useAuth from "../../../hooks/useAuth";
import useDebounce from "../../../hooks/useDebounce";
import { getPagamentosContratoAluguel } from "../../../services/beneficiarios";

import { Delete } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CustomHeader from "../../../components/CustomHeader/CustomHeader";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { MenuOptionsTable } from "../../../components/MenuOptionsTable";

moment.locale();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  header: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  dadosBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
    marginLeft: "30px",
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  contadorStyle: {
    display: "flex",
    fontSize: "30px",
    fontFamily: "Montserrat-SemiBold",
  },
  paper: {
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: "0px",
    alignSelf: "center",
  },
  modal: {
    outline: " none",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    position: "absolute",
    top: "10%",
    left: "35%",
    width: "30%",
    height: "80%",
    backgroundColor: "white",
    border: "0px solid #000",
    boxShadow: 24,
  },
  closeModalButton: {
    alignSelf: "end",
    padding: "5px",
    "&:hover": {
      backgroundColor: APP_CONFIG.mainCollors.primaryVariant,
      cursor: "pointer",
    },
  },
}));

const columns = [
  {
    headerText: "DATA",
    key: "created_at",
    CustomValue: (created_at) => {
      return <>{moment.utc(created_at).format("DD MMMM YYYY")}</>;
    },
  },
  {
    headerText: "DATA PAGAMENTO",
    key: "data_pagamento",
    CustomValue: (date) => {
      return <>{moment.utc(date).format("DD MMMM YYYY")}</>;
    },
  },
  { headerText: "NOME LOCADOR", key: "contrato_aluguel.nome" },
  { headerText: "DOCUMENTO LOCADOR", key: "contrato_aluguel.documento" },
  {
    headerText: "NOME BENEFICIÁRIO",
    key: "contrato_aluguel.locatario.user.nome",
  },
  {
    headerText: "DOCUMENTO BENEFICIÁRIO",
    key: "contrato_aluguel.locatario.user.documento",
  },
  {
    headerText: "TIPO",
    key: "contrato_aluguel.tipo_transacao",
    CustomValue: (tipo) => (tipo === "Dict" ? "Pix" : "Manual"),
  },
  {
    headerText: "DADOS",
    key: "",
    FullObject: (data) =>
      data?.contrato_aluguel?.chave_pix ||
      `${data?.contrato_aluguel?.banco} ${data?.contrato_aluguel?.agencia} ${data?.contrato_aluguel?.conta_sem_digito}-${data?.contrato_aluguel?.digito_conta}`,
  },
  {
    headerText: "Valor",
    key: "valor",
    CustomValue: (valor) => {
      return (
        <Box>
          R${" "}
          {parseFloat(valor).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      );
    },
  },
  {
    headerText: "DURAÇÃO",
    key: "",
    FullObject: (data) => (
      <>
        {moment.utc(data?.contrato_aluguel?.data_inicio).format("DD/MM/YY")} a{" "}
        {moment.utc(data?.contrato_aluguel?.data_fim).format("DD/MM/YY")}
      </>
    ),
  },
  { headerText: "STATUS", key: "status" },
  { headerText: "", key: "menu" },
];

export default function PagamentoContratoAluguel() {
  const id = useParams()?.id ?? "";
  const token = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [lista, setLista] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    created_at: "",
    data_pagamento: "",
    status: " ",
    contrato_aluguel_id: "",
    like: "",
    nome_beneficiario: "",
  });
  const debouncedFilters = useDebounce(filter, 800);

  const resetFilters = () => {
    setFilter({
      created_at: "",
      data_pagamento: "",
      status: " ",
      contrato_aluguel_id: "",
      like: "",
      nome_beneficiario: "",
    });
  };

  const filters = `created_at=${debouncedFilters.created_at}&data_pagamento=${debouncedFilters.data_pagamento}&contrato_aluguel_id=${debouncedFilters.contrato_aluguel_id}&status=${debouncedFilters.status}&like=${debouncedFilters.like}&nome_beneficiario=${debouncedFilters.nome_beneficiario}`;

  const getData = async (token, page = 1) => {
    setLoading(true);
    try {
      const { data } = await getPagamentosContratoAluguel(
        token,
        id,
        page,
        "",
        filters
      );
      setLista(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(token, page);
  }, [token, page, debouncedFilters]);

  useEffect(() => {
    dispatch(loadUserData(token));
  }, [dispatch, token]);

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <CustomHeader pageTitle="Pagamento Contrato de Aluguel" />

        <Box className={classes.dadosBox}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                alignItems: "center",
                borderRadius: "17px",
                flexDirection: "column",
                minWidth: "100%",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  borderRadius: 27,
                  borderTopLeftRadius: 27,
                  borderTopRightRadius: 27,
                }}
              >
                <Box
                  display="flex"
                  style={{
                    marginTop: "10px",
                    marginBottom: "16px",
                    margin: 30,
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      borderTopRightRadius: 27,
                      borderTopLeftRadius: 27,
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                        borderTopLeftRadius: 27,
                        borderTopRightRadius: 27,
                      }}
                    >
                      <Box style={{ margin: 30 }}>
                        <Grid
                          container
                          spacing={3}
                          style={{ alignItems: "center", marginBottom: "8px" }}
                        >
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="Pesquisar por data"
                              size="small"
                              variant="outlined"
                              style={{
                                marginRight: "10px",
                              }}
                              InputLabelProps={{
                                color: APP_CONFIG.mainCollors.secondary,
                                shrink: true,
                                pattern: "d {4}- d {2}- d {2} ",
                              }}
                              type="date"
                              value={filter.created_at}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  created_at: e.target.value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="Pesquisar por data de pagamento"
                              size="small"
                              variant="outlined"
                              style={{
                                marginRight: "10px",
                              }}
                              InputLabelProps={{
                                color: APP_CONFIG.mainCollors.secondary,
                                shrink: true,
                                pattern: "d {4}- d {2}- d {2} ",
                              }}
                              type="date"
                              value={filter.data_pagamento}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  data_pagamento: e.target.value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Select
                              fullWidth
                              variant="outlined"
                              value={filter.status}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  status: e.target.value,
                                }));
                              }}
                            >
                              <MenuItem value={" "}>Status</MenuItem>
                              <MenuItem value={"created"}>created</MenuItem>
                              <MenuItem value={"succeeded"}>succeeded</MenuItem>
                              <MenuItem value={"confirmed"}>confirmed</MenuItem>
                              <MenuItem value={"pending"}>pending</MenuItem>
                              <MenuItem value={"failed"}>failed</MenuItem>
                              <MenuItem value={"ErrorBalance"}>
                                ErrorBalance
                              </MenuItem>
                              <MenuItem value={"Error"}>Error</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              placeholder="Pesquisar por ID do contrato"
                              size="small"
                              variant="outlined"
                              style={{
                                marginRight: "10px",
                              }}
                              value={filter.contrato_aluguel_id}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  contrato_aluguel_id: e.target.value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              placeholder="Pesquisar por locador"
                              size="small"
                              variant="outlined"
                              style={{
                                marginRight: "10px",
                              }}
                              value={filter.like}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  like: e.target.value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              placeholder="Pesquisar por beneficiário"
                              size="small"
                              variant="outlined"
                              style={{
                                marginRight: "10px",
                              }}
                              value={filter.nome_beneficiario}
                              onChange={(e) => {
                                setPage(1);
                                setFilter((prev) => ({
                                  ...prev,
                                  nome_beneficiario: e.target.value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <CustomButton color="red" onClick={resetFilters}>
                                <Box display="flex" alignItems="center">
                                  <Delete />
                                  Limpar
                                </Box>
                              </CustomButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    {!loading && lista.data && lista.per_page ? (
                      <>
                        <Box minWidth={!matches ? "800px" : null}>
                          <CustomTable
                            columns={columns ? columns : null}
                            data={lista.data}
                            Editar={({ row }) => (
                              <MenuOptionsTable
                                row={row}
                                getData={getData}
                                JSONResponse={row?.response}
                                // patchStatus={
                                //   row.status === "succeeded" ? null : "contrato"
                                // }
                              />
                            )}
                          />
                        </Box>

                        <Box alignSelf="flex-end" marginTop="8px">
                          <Pagination
                            variant="outlined"
                            color="secondary"
                            size="large"
                            count={lista.last_page}
                            onChange={(e, value) => setPage(value)}
                            page={page}
                          />
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <LinearProgress color="secondary" />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
